export default {
    option: (provided, state) => {
        const highlight = state.isFocused || state.isSelected;
        let customStyles = {
            ...provided,
            fontSize: '1.6rem',
            color: '#000',
            '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
            }
        }

        if (highlight) {
            customStyles = {
                ...customStyles,
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
            }
        }

        return {
            ...provided,
            ...customStyles,
        }
    },
    groupHeading: (provided, _state) => ({
        ...provided,
        fontSize: '1.2rem',
        frontWeight: 500,
        color: '#000',
        textTransform: 'uppercase',
        marginBottom: '5px',
    }),
    control: (provided, _state) => ({
        ...provided,
        height: '66px',
        borderRadius: '2px',
        cursor: 'text',
        backgroundColor: '#FFF',
        border: '1px solid var(--colorGray100);',
        ':hover': {
            border: '1px solid var(--colorGray100);',
        }
    }),
    valueContainer: (provided, _state) => ({
        ...provided,
        paddingLeft: '40px',
        lineHeight: '21px',
        overflow: 'visible',
    }),
    indicatorSeparator: (provided, _state) => ({
        ...provided,
        opacity: 0,
    }),
    dropdownIndicator: (provided, _state) => ({
        ...provided,
        opacity: 0,
    }),
    placeholder: (provided, _state) => ({
        ...provided,
        color: 'var(--colorGray100)',
    }),
    menu: (provided, _state) => ({
        ...provided,
        marginTop: 0,
        borderTop: 0,
        borderRadius: '2px',
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
    }),
    valueContainer: (provided, _state) => ({
        ...provided,
        paddingLeft: '40px',
        overflow: 'visible',
        lineHeight: '21px',
        // md
        "@media (min-width: 832px)": {
            overflow: 'hidden',
            paddingLeft: '50px',
        }
    })
}
