import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import i18n from '../../i18n';
import Select from 'react-select';
import classNames from 'classnames';
import { ThemeContext, themes } from '../../themes/ThemeContext';
import stylesSelectNordr from './FindYourEstate.nordr.selectStyle';
import stylesNordr from './FindYourEstate.nordr.module.scss';
import stylesFolkhem from './FindYourEstate.folkhem.module.scss';

const getStyles = (theme) => ({
        [themes.NORDR]: stylesNordr,
        [themes.FOLKHEM]: stylesFolkhem,
    }[theme]
);

const FindYourEstate = ({ title, groupedItems, quickLinks }) => {
    const theme = useContext(ThemeContext);
    const styles = getStyles(theme);

    let selectStyle = null;

    if (theme === themes.NORDR) {
        selectStyle = stylesSelectNordr;
    }
    if (theme === themes.FOLKHEM) {
        selectStyle = {};  // Folkhem has no styling
    }

    return (
        <div className={styles["FindYourEstate"]}>
            <div className={styles["FindYourEstate__Content"]}>
                <h2 className={styles["FindYourEstate__Title"]}>{title}</h2>

                <div className={styles["FindYourEstate__Fields"]}>
                    <div className={styles["FindYourEstate__FieldContainer"]}>
                        <label
                            className={styles["FindYourEstate__Label"] + " " + "sr-only"}
                            htmlFor="id_search">
                            {i18n.t('findYourEstate.placeholder')}
                        </label>
                        <div className={styles["FindYourEstate__SelectContainer"]}>
                            <Select
                                options={groupedItems}
                                inputId="id_search"
                                placeholder={i18n.t('findYourEstate.placeholder')}
                                classNamePrefix={styles["FindYourEstate__Select"]}
                                styles={selectStyle}
                                onChange={({value}) => {
                                    if(typeof window !== 'undefined') {
                                        window.location.href = value
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>

                <div className={classNames(
                    styles["FindYourEstate__QuickLinks"],
                    styles["FindYourEstate__QuickLinks--Size"+quickLinks.length],
                )}>
                    {quickLinks.map(({ label, url, icon }) => {
                        const classes = classNames(
                            styles["FindYourEstate__QuickLink"],
                            styles[`FindYourEstate__QuickLink--${icon}`],
                        );

                        return (
                            <a className={classes} href={url} key={url}>
                                <span className={styles["FindYourEstate__LinkText"]}>
                                    {label}
                                </span>
                            </a>
                        )
                    })}
                </div>
            </div>
        </div>
    );
}

FindYourEstate.propTypes = {
    title: PropTypes.string,
    regions: PropTypes.array,
    baseUrl: PropTypes.string,
    groupedItems: PropTypes.array,
    quickLinks: PropTypes.array,
};

FindYourEstate.defaultProps = {
    regions: null,
    groupedItems: [],
    quickLinks: [],
    title: i18n.t('findYourEstate.title'),
};

export default FindYourEstate;
